import translationAxios from "@/axios/translationAxios"

/**
 * get Translation map.
 * @function getTranslationMap
 * @param {Object} data
 * @param {String} data.langCode
 * @param {Array} data.appPrefixes
 * @returns {promise} axios
 */
export function getTranslationMap(data) {
    return translationAxios({
        method: 'post',
        url: 'client/translations',
        data
    })
}